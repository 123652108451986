@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lusitana:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lusitana:wght@400;700&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.useful {
    display: flex;
    overflow: hidden;


    &-section {
        width: 50%;
        border: 1px solid #333;
        border-radius: 25px;

        @media (max-width: 900px) {
            width: 100%;
        }

        &__list {
            font-family: "Tinos", serif;
            font-weight: normal;
            font-size: 1.73vw;
            font-style: italic;
            color: #333;
            list-style-position: inside;
            padding-left: 0;
            text-align: left;
            padding: 40px;
            font-size: 50px;
            font-weight: 400;

            @media (max-width: 500px) {
                font-size: 30px;
            }

            @media (max-width: 300px) {
                font-size: 20px;
                padding: 10px;
            }


        }

        &__bg {
            width: 50%;
            // background-image: url('../img/sunflower.png');
            margin-left: 100px;

            &_img {
                width: 100%;
                height: 90%;
                padding: 40px 20px;
                text-align: center;
                color: #fff;
                position: relative;
                overflow: hidden;
                background-image: url('../img/biology-1.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            @media (max-width:1200px) {
                display: none;
            }
        }
    }

}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    width: 400px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;

}

.modal_content_text {
    padding-left: 10px;


    &__link_pay {
        margin-bottom: 50px;
        margin-top: 30px;
        color: green;
        text-decoration: none;
        background-color: darkseagreen;
        padding: 10px;
        font-size: 15px;
        border-radius: 25px;

        @media (max-width: 600px) {
            padding: 5px;
        }
    }
}

.close {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}

.modal-img {
    max-width: 100%;
    height: auto;
    margin-bottom: 5px;
    border-radius: 4px;
}

.modal-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.modal-link {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
}

.modal-link:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .modal-content {
        padding: 15px;
        width: 85%;
    }

    .modal-text {
        font-size: 14px;
    }

    .modal-link {
        font-size: 14px;
    }

    .close {
        font-size: 20px;
    }
}
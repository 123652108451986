.intensive-benefits {
    background-color: #333;
    // background-image: url('../img/sunflower.png');
    padding: 40px 20px;
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 10%;
        width: 200px;
        height: 600px;
        border-radius: 50% 50% 0 0;
        border: 4px solid #fffcfc;
        border-bottom: none;
        border-right: none;
        transform: rotate(-45deg) translate(-100px, 100px);
        opacity: 0.2;
    }


    &__title {
        font-size: 2rem;
        margin-bottom: 40px;



        span {
            font-family: 'Cursive', sans-serif;
            color: #b87333;
        }
    }

    &__circles {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        flex-wrap: wrap;
        z-index: 10;


        .intensive-benefits__circle {
            background-color: #444;
            color: #fff;
            width: 200px;
            height: 200px;
            border-radius: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.4;
            border: solid 1px white;
            z-index: 11;

            @media (max-width:700px) {
                font-size: 1rem;
            }


            &--highlighted {
                background-color: #fff;
                color: #333;
                font-weight: bold;
                margin-right: -20px;


                &-last {
                    margin-bottom: 120px;
                }
            }
        }

        ::after {
            content: "";
            position: absolute;
            top: 0;
            right: 10%;
            width: 200px;
            height: 600px;
            border-radius: 50% 50% 0 0;
            border: 4px solid #fffcfc;
            border-bottom: none;
            border-right: none;
            transform: rotate(-45deg) translate(-100px, 100px);
            opacity: 0.2;
            z-index: 0;
        }
    }


    @keyframes draw-line {
        0% {
            width: 0;
        }

        100% {
            width: 90%;
        }
    }
}

@media (max-width: 768px) {
    .intensive-benefits {
        &__title {
            font-size: 1.5rem;
        }

        &__circles {
            flex-direction: column;

            .intensive-benefits__circle {
                width: 150px;
                height: 150px;
            }
        }
    }
}